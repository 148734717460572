<template>
  <nav 
    class="h-full bg-white flex flex-col text-gray-text_dark text-sm font-medium overflow-y-auto transform translate-x-0 rounded-r-xl"
    :style="{ transform: mobile_menu ? 'translateX(0)' : '' }"
  >
    <div class="block lg:hidden px-3 pt-3">
      <img src="@/assets/images/logo.png" alt="Logo" />
    </div>

    <!-- <div class="flex items-center justify-between p-2 m-4 rounded-lg h-14 bg-gray-background">
      <div class="w-1/2 h-full">
        <button @click="open_sub_menu(50, 'overview')" class="overview_section_button_left">
          <svg-icon icon-class="overview" className="gray-dark"></svg-icon>
          <span>總覽</span>
        </button>
      </div>
      <div class="w-1/2 h-full">
        <button @click="open_sub_menu(51, 'setting')" class="overview_section_button_right">
          <svg-icon icon-class="setting" className="gray-dark"></svg-icon>
          <span>設定</span>
        </button>
      </div>
    </div> -->

    <div class="flex-grow bg-white w-full divide-y-2 divide-gray-background">
      <!-- main menu -->
      <section class="pb-5 pt-0 flex flex-col space-y-1 mt-5">
        <div class="menu" v-for="(item, index) in main_menu" :key="item.title">
          <!-- 若為有subMenu的選單 -->
          <button v-if="item.subMenu.length > 0" @click="open_sub_menu(index, item.routerName)" class="menu_title">
            <svg-icon :icon-class="item.iconName" className="gray-dark"></svg-icon>
            <span>{{ item.title }}</span>
          </button>

          <!-- 只有一層的選單 -->
          <router-link @click.native="close_sub_menu" v-else :to="{ name: item.routerName }" class="menu_title">
            <svg-icon :icon-class="item.iconName" className="gray-dark"></svg-icon>
            <span>{{ item.title }}</span>
          </router-link>

          <div class="sub_menu" :class="[opening_sub_menu === index ? 'h-auto' : 'h-0']">
            <router-link v-for="subItem in item.subMenu" :key="subItem.title" :to="{ name: subItem.routerName }">{{
              subItem.title
            }}</router-link>
          </div>
        </div>
      </section>

      <!-- web_setting_menu -->
      <section class="py-5 flex flex-col space-y-1">
        <div class="menu" v-for="item in web_setting_menu" :key="item.title">
          <button @click="open_sub_menu(item.index, item.routerName)" class="menu_title justify-between">
            <div class="flex items-center space-x-2">
              <svg-icon :icon-class="item.iconName" className="gray-dark"></svg-icon>
              <span>{{ item.title }}</span>
            </div>
            <a
              style="margin-right: 12px"
              :href="$store.state.getWebData.param.wlink"
              target="_blank"
              class="flex items-center px-1 rounded-md hover:bg-white transition-colors"
            >
              <svg-icon icon-class="eye_view" className="gray-dark"></svg-icon>
              <!-- <span>前台</span> -->
            </a>
          </button>
          <div class="sub_menu" :class="[opening_sub_menu === item.index ? 'h-auto' : 'h-0']">
            <router-link v-for="subItem in item.subMenu" :key="subItem.title" :to="{ name: subItem.routerName }">{{
              subItem.title
            }}</router-link>
          </div>
        </div>
      </section>

      <!-- footer fixed -->
      <div class="fixed w-full bottom-0 left-0 bg-white">
        <!-- <router-link :to="{ name: 'manual' }" class="font-medium flex items-center justify-center space-x-2 text-base">
          <svg-icon icon-class="manual" className="gray-dark"></svg-icon>
          <span>開店手冊</span>
        </router-link> -->
        <section class="py-5 flex flex-col space-y-1">
          <div class="menu" v-for="item in footer_menu" :key="item.title">
            <router-link
              @click.native="close_sub_menu"
              :to="{
                name: item.routerName,
              }"
              class="menu_title justify-between"
            >
              <div class="flex items-center space-x-2">
                <svg-icon :icon-class="item.iconName" className="gray-dark"></svg-icon>
                <span>{{ item.title }}</span>
              </div>
            </router-link>
          </div>
        </section>
      </div>
    </div>
  </nav>
</template>

<script>
export default {
  computed: {
    mobile_menu() {
      return this.$store.state.mobile_menu
    },
  },
  data() {
    return {
      main_menu: [
        {
          title: '總覽',
          iconName: 'overview',
          routerName: 'overview',
          subMenu: [],
        },
        {
          title: '訂單管理',
          iconName: 'order',
          routerName: 'orders',
          subMenu: [
            {
              title: '所有訂單',
              routerName: 'orders',
            },
            {
              title: '創建訂單',
              routerName: 'create_order',
            },
            // {
            //   title: '待結帳訂單',
            //   routerName: 'checkouts',
            // },
          ],
        },
        {
          title: '商品管理',
          iconName: 'product',
          routerName: 'products',
          subMenu: [
            {
              title: '所有商品',
              routerName: 'products',
            },
            // {
            //   title: '庫存',
            //   routerName: 'inventories',
            // },
            {
              title: '分類',
              routerName: 'collections',
            },
            // {
            //   title: '加購品',
            //   routerName: 'add_items',
            // },
            // {
            //   title: '贈品',
            //   routerName: 'freebies',
            // },
            {
              title: '標籤和供應商',
              routerName: 'tags_vendors',
            },
            // {
            //   title: '評價',
            //   routerName: 'reviews',
            // },
          ],
        },
        {
          title: '會員管理',
          iconName: 'member',
          routerName: 'customers',
          subMenu: [
            {
              title: '所有會員',
              routerName: 'customers',
            },
            {
              title: '會員等級',
              routerName: 'memberships',
            },
            {
              title: '購物金',
              routerName: 'giftVoucher',
            },
            {
              title: '點數回饋',
              routerName: 'rewardsPoints',
            },
          ],
        },
        {
          title: '行銷活動',
          iconName: 'promotion',
          routerName: 'storewide_discounts',
          subMenu: [
            {
              title: '全店優惠',
              routerName: 'storewide_discounts',
            },
            {
              title: '商品折扣',
              routerName: 'product_discounts',
            },
            {
              title: '滿額免運',
              routerName: 'free_shippings',
            },
            {
              title: '優惠碼',
              routerName: 'coupons',
            },
          ],
        },
        {
          title: '報表管理',
          iconName: 'report',
          routerName: 'report_products',
          subMenu: [
            {
              title: '商品銷售報表',
              routerName: 'report_products',
            },
          ],
        },
        // {
        //   title: '進銷存',
        //   iconName: 'inventory',
        //   routerName: 'psi_records',
        //   subMenu: [
        //     {
        //       title: '進銷存記錄',
        //       routerName: 'psi_records',
        //     },
        //     {
        //       title: '匯入庫存',
        //       routerName: 'inventories_import',
        //     },
        //   ],
        // },
      ],
      web_setting_menu: [
        {
          title: '網站管理',
          iconName: 'websetting',
          routerName: 'themes',
          index: 99,
          subMenu: [
            {
              title: '佈景主題',
              routerName: 'themes',
            },
            {
              title: '目錄選單',
              routerName: 'menus',
            },
            {
              title: '網站分頁',
              routerName: 'pages',
            },
            {
              title: '部落格文章',
              routerName: 'posts',
            },
          ],
        },
      ],
      footer_menu: [
        {
          title: '設定',
          iconName: 'setting',
          routerName: 'setting',
        },
        // {
        //   title: '開店手冊',
        //   iconName: 'manual',
        //   routerName: 'manual',
        // },
      ],
      opening_sub_menu: '',
    }
  },
  watch: {
    $route: {
      handler(route) {
        let index = route.meta.sideMenuIndex
        this.open_sub_menu(index)
      },
      immediate: true,
    },
  },
  // mounted() {
  // this.open_sub_menu(parseInt(localStorage.opening_submenu_index), undefined)
  // },
  methods: {
    open_sub_menu(index, routerName) {
      // if (!index) return
      // 儲存打開中的選單，重整後預設打開
      // localStorage.opening_submenu_index = index
      this.opening_sub_menu = index

      if (!routerName) return

      // 若視窗大小 >= 1024 就直接跳轉
      if (parseInt(document.body.clientWidth, 10) >= 1024) {
        this.$router
          .push({
            name: routerName,
          })
          .catch((err) => {})
      }
    },
    close_sub_menu() {
      this.opening_sub_menu = ''
      // localStorage.opening_submenu_index = ''
    },
  },
}
</script>

<style lang="scss" scoped>
nav {
  position: fixed;
  width: 260px;
  // top: calc(56px + 20px);
  top: 64px; // header的高度
  height: calc(100vh - 64px);
  @media (max-width: 1024px) {
    z-index: 3000;
    position: fixed;
    left: 0;
    top: 0;
    transform: translateX(-100%);
    height: 100vh;
    @apply transition-transform duration-500 ease-in-out;
  }
}

.menu {
  @apply w-full pr-4 space-y-1;
}

.menu_title {
  @apply w-full space-x-2 text-left flex items-center pl-7 h-8 rounded-r-md hover:bg-gray-background transition-colors relative;

  &::after {
    content: '';
    background: var(--success);
    width: 5px;
    height: 100%;
    position: absolute;
    left: 0;
    top: 0;
    transform: translateX(-100%);
    border-top-right-radius: 10px;
    border-bottom-right-radius: 10px;
    transition: transform 0.3s ease-in-out;
  }

  &.router-link-active {
    @apply bg-blue-50 text-success;

    &::after {
      transform: translateX(0);
    }
  }
}

.sub_menu {
  @apply flex flex-col overflow-hidden space-y-1;

  a {
    @apply h-8 flex items-center hover:bg-gray-background transition-colors  rounded-r-md relative text-gray-400;
    padding-left: 58px;

    &::after {
      content: '';
      background: var(--success);
      width: 5px;
      height: 100%;
      position: absolute;
      left: 0;
      top: 0;
      transform: translateX(-100%);
      border-top-right-radius: 10px;
      border-bottom-right-radius: 10px;
      transition: transform 0.3s ease-in-out;
    }

    &.router-link-active {
      @apply bg-blue-50 text-success;

      &::after {
        transform: translateX(0);
      }
    }
  }
}

button {
  @apply font-medium;
}

.overview_section_button_left {
  @apply flex items-center justify-start px-1 space-x-2 h-full w-fit rounded-md hover:bg-white transition-colors;
}
.overview_section_button_right {
  @apply flex items-center justify-start px-1 space-x-2 h-full w-fit rounded-md hover:bg-white transition-colors;
}
</style>
