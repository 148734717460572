<template>
  <div class="min-h-screen bg-gray-background">
    <adminHeader></adminHeader>
    <main class="">
      <sideMenu></sideMenu>
      <transition name="fade">
        <router-view class="router_view"></router-view>
      </transition>
      <vue-progress-bar></vue-progress-bar>
    </main>
    <div id="fb-root"></div>
  </div>
</template>

<script>
import adminHeader from '@/components/header.vue'
import sideMenu from '@/components/sideMenu.vue'

export default {
  components: {
    adminHeader,
    sideMenu,
  },
  data() {
    return {
      pageId: '',
    }
  },
  watch: {
    $route(to, from) {
      // 若在手機選單上跳轉頁面時，手機選單關閉
      if (this.$store.state.mobile_menu) this.$store.dispatch('closeOverlay')
    },
  },
  created() {
    this.pageId = process.env.VUE_APP_FB_PAGE_ID
  },
  methods: {
    // createFbChat(scope) {},
  },
  mounted() {
    if (this.pageId != '') {
      ;(function (scope) {
        window.fbAsyncInit = function () {
          FB.init({
            xfbml: true,
            version: 'v4.0',
          })
          const elem = document.createElement('div')
          elem.setAttribute('class', 'fb-customerchat')
          elem.setAttribute('attribution', 'setup_tool')
          elem.setAttribute('page_id', scope.pageId)
          document.body.appendChild(elem)
        }
      })(this)
      ;(function (d, s, id) {
        var js,
          fjs = d.getElementsByTagName(s)[0]
        if (d.getElementById(id)) return
        js = d.createElement(s)
        js.id = id
        js.src = 'https://connect.facebook.net/zh_TW/sdk/xfbml.customerchat.js'
        fjs.parentNode.insertBefore(js, fjs)
      })(document, 'script', 'facebook-jssdk')
    }
  },
}
</script>

<style lang="scss" scoped>
.router_view {
  @apply pt-20 pb-20 px-3 lg:px-5
  bg-gray-background
  relative
  text-sm text-gray-text_light;

  width: 100%;
  height: 100%;

  @screen lg {
    width: calc(100% - 260px);
    // height: calc(100% - 12px);
    padding-top: 5px;
    left: 260px;
    top: 64px; // header的高度
  }
}
</style>
